import './style.css'
import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

//Perlin noise Lib
class Perlin {
    constructor() {
      this.grad3 =
        [[1,1,0],[-1,1,0],[1,-1,0],[-1,-1,0],
         [1,0,1],[-1,0,1],[1,0,-1],[-1,0,-1],
         [0,1,1],[0,-1,1],[0,1,-1],[0,-1,-1]];
      this.p = [];
      for (var i=0; i<256; i++) {
        this.p[i] = Math.floor(Math.random()*256);
      }
  
      // To remove the need for index wrapping, double the permutation table length
      this.perm = [];
      for(i=0; i<512; i++) {
        this.perm[i]=this.p[i & 255];
      }
  
      // A lookup table to traverse the simplex around a given point in 4D.
      // Details can be found where this table is used, in the 4D noise method.
      this.simplex = [
        [0,1,2,3],[0,1,3,2],[0,0,0,0],[0,2,3,1],[0,0,0,0],[0,0,0,0],[0,0,0,0],[1,2,3,0],
        [0,2,1,3],[0,0,0,0],[0,3,1,2],[0,3,2,1],[0,0,0,0],[0,0,0,0],[0,0,0,0],[1,3,2,0],
        [0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],
        [1,2,0,3],[0,0,0,0],[1,3,0,2],[0,0,0,0],[0,0,0,0],[0,0,0,0],[2,3,0,1],[2,3,1,0],
        [1,0,2,3],[1,0,3,2],[0,0,0,0],[0,0,0,0],[0,0,0,0],[2,0,3,1],[0,0,0,0],[2,1,3,0],
        [0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],
        [2,0,1,3],[0,0,0,0],[0,0,0,0],[0,0,0,0],[3,0,1,2],[3,0,2,1],[0,0,0,0],[3,1,2,0],
        [2,1,0,3],[0,0,0,0],[0,0,0,0],[0,0,0,0],[3,1,0,2],[0,0,0,0],[3,2,0,1],[3,2,1,0]];
    }
  
    dot(g, x, y) {
      return g[0]*x + g[1]*y;
    }
  
    noise(xin, yin) {
      var n0, n1, n2; // Noise contributions from the three corners
      // Skew the input space to determine which simplex cell we're in
      var F2 = 0.5*(Math.sqrt(3.0)-1.0);
      var s = (xin+yin)*F2; // Hairy factor for 2D
      var i = Math.floor(xin+s);
      var j = Math.floor(yin+s);
      var G2 = (3.0-Math.sqrt(3.0))/6.0;
      var t = (i+j)*G2;
      var X0 = i-t; // Unskew the cell origin back to (x,y) space
      var Y0 = j-t;
      var x0 = xin-X0; // The x,y distances from the cell origin
      var y0 = yin-Y0;
      // For the 2D case, the simplex shape is an equilateral triangle.
      // Determine which simplex we are in.
      var i1, j1; // Offsets for second (middle) corner of simplex in (i,j) coords
      if(x0>y0) {i1=1; j1=0;} // lower triangle, XY order: (0,0)->(1,0)->(1,1)
      else {i1=0; j1=1;}      // upper triangle, YX order: (0,0)->(0,1)->(1,1)
      // A step of (1,0) in (i,j) means a step of (1-c,-c) in (x,y), and
      // a step of (0,1) in (i,j) means a step of (-c,1-c) in (x,y), where
      // c = (3-sqrt(3))/6
      var x1 = x0 - i1 + G2; // Offsets for middle corner in (x,y) unskewed coords
      var y1 = y0 - j1 + G2;
      var x2 = x0 - 1.0 + 2.0 * G2; // Offsets for last corner in (x,y) unskewed coords
      var y2 = y0 - 1.0 + 2.0 * G2;
      // Work out the hashed gradient indices of the three simplex corners
      var ii = i & 255;
      var jj = j & 255;
      var gi0 = this.perm[ii+this.perm[jj]] % 12;
      var gi1 = this.perm[ii+i1+this.perm[jj+j1]] % 12;
      var gi2 = this.perm[ii+1+this.perm[jj+1]] % 12;
      // Calculate the contribution from the three corners
      var t0 = 0.5 - x0*x0-y0*y0;
      if(t0<0) n0 = 0.0;
      else {
        t0 *= t0;
        n0 = t0 * t0 * this.dot(this.grad3[gi0], x0, y0);  // (x,y) of grad3 used for 2D gradient
      }
      var t1 = 0.5 - x1*x1-y1*y1;
      if(t1<0) n1 = 0.0;
      else {
        t1 *= t1;
        n1 = t1 * t1 * this.dot(this.grad3[gi1], x1, y1);
      }
      var t2 = 0.5 - x2*x2-y2*y2;
      if(t2<0) n2 = 0.0;
      else {
        t2 *= t2;
        n2 = t2 * t2 * this.dot(this.grad3[gi2], x2, y2);
      }
      // Add contributions from each corner to get the final noise value.
      // The result is scaled to return values in the interval [-1,1].
      return 70.0 * (n0 + n1 + n2);
    }
}

let perlin = new Perlin();
//texture loader
const loader = new THREE.TextureLoader();
const height = loader.load('https://artifactslab.ca/wp-content/themes/avo/js/Height.png');
const alpha = loader.load('https://artifactslab.ca/wp-content/themes/avo/js/alpha.png');
const star = loader.load('https://artifactslab.ca/wp-content/themes/avo/js/star.png');


// // Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const planeGeometry = new THREE.PlaneBufferGeometry(4,4,64,64);

const particlesGeometry = new THREE.BufferGeometry;
const particleCount = 5000;
const posArray = new Float32Array(particleCount * 3);
// xyz, xyz

for (let index = 0; index < particleCount * 3; index++) {
    //posArray[index] = Math.random();
    posArray[index] = (Math.random() - 0.5) * 5;
}
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray,3));


const sphereGeometry = new THREE.SphereGeometry(3);


// Materials
const terrainMaterial = new THREE.MeshStandardMaterial({
    color: '#964B00',
    alphaMap: alpha,
    transparent: true,
    displacementMap: height,
    depthTest: false,
    wireframe: true,
    side: THREE.DoubleSide
})


const particleMaterial = new THREE.PointsMaterial({
    size: 0.0085,
    map:star,
    transparent: true,
    depthTest: false,
    color: '#17447a',
})


const sphereMaterial = new THREE.MeshPhysicalMaterial({
    color: 'white',
    //transparent : true,
    //opacity: 0.1,
    alphaMap: alpha,
    roughness: 0,
    transmission:1,
    thickness:0.5,
    //reflectivity: 1,
    //clearcoat: 1,
    depthTest: false,
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending
})

// Mesh
const plane = new THREE.Mesh(planeGeometry, terrainMaterial);


const particles = new THREE.Points(particlesGeometry,particleMaterial);
const sphere = new THREE.Mesh(sphereGeometry,sphereMaterial);


scene.add(particles,plane,sphere)

plane.rotation.x = 181;
//gui.add(plane.rotation, 'x');


// Lights
//const ambientLight = new THREE.AmbientLight('#17447a',2);
const pointLight = new THREE.PointLight('#464646', 1)
const pointLight_2 = new THREE.PointLight('#464646', 1)

pointLight.position.x = 0.2
pointLight.position.y = 21.5
pointLight.position.z = -10.8
pointLight.power = 5000;


pointLight_2.position.x = 1
pointLight_2.position.y = 0
pointLight_2.position.z = -2
pointLight_2.power = 1000;
pointLight_2.decay = 2;

scene.add(pointLight,pointLight_2)

// gui.add(pointLight_2.position,'x');
// gui.add(pointLight_2.position,'y');
// gui.add(pointLight_2.position,'z');

//gui.add(camera.aspect,'CamAsp');

// const col = {color : '#ffffff'};

// gui.addColor(col,'color').onChange(() => {
//    pointLight.color.set(col.color)
// })

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -1.3
camera.position.y = 0.88
camera.position.z = 3.4

// gui.add(camera.position,'x');
// gui.add(camera.position,'y');
// gui.add(camera.position,'z');


scene.add(camera)


// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
//renderer.setClearColor(new THREE.Color('#21282a'), 1)

/**
 * Animate
 */

document.addEventListener('mousemove',animateTerrain);

let mouseY;

function animateTerrain(event){
    mouseY = event.clientY;
    //console.log(mouseY);
}


function updateVertecies(geom,t,s=0.00008){
    var vertices = geom.geometry.attributes.position.array;

    for(var i = 0; i <= vertices.length; i+=3){
        vertices[i+2] = perlin.noise(vertices[i] + t,vertices[i+1] + t) * (0.15 + s); 
    }
    geom.geometry.attributes.position.needsUpdate = true;
}

const clock = new THREE.Clock()

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    // plane.material.displacementScale =  0.3 + mouseY * 0.0008;

    updateVertecies(plane,elapsedTime * 0.1);

    particles.rotation.y = -(200 * elapsedTime) * 0.0008;

    //camera.fov = mouseY * 0.005;
    camera.lookAt(sphere.position)

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()